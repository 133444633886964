import { Layout, UserContext } from '../components/Layout'
import React from 'react'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { Grid, Cell } from 'baseui/layout-grid'
import { HorizontalLine } from '../components/Line'
import { useStyletron } from 'baseui'
import { ListNavItem, ListNav } from '../components/ListNav'
import { getGuideList, ToolsPagesListResponse, LiteToolsPagesResponseItem } from '../api/cms'
import { ErrorNotification } from '../components/Notification'
import { handleErrorFromAPI, loginRedirect } from '../utils'

export const GuideList = () => {
  const [guides, setGuides] = React.useState<LiteToolsPagesResponseItem[] | null>(null)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)
  const { token, setUserContext } = React.useContext(UserContext)

  React.useEffect(() => {
    if (token) {
      getGuideList(token)
        .then((response: ToolsPagesListResponse) => {
          if (response.meta.total_count > 0) {
            setGuides(response.items)
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    } else {
      loginRedirect()
    }
  }, [token, setUserContext])

  const listItems = guides
    ? guides.map(guide => (
        <ListNavItem url={`/tools?id=${guide.id}`} key={guide.id}>
          {guide.title}
        </ListNavItem>
      ))
    : null

  return (
    <>
      <ErrorNotification hasError={loadingErrorActive} />
      <ListNav>{listItems}</ListNav>
    </>
  )
}

export const GuideListPage = () => {
  const [css] = useStyletron()
  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <HeadingLevel>
            <Heading>Guides</Heading>
            <HorizontalLine
              className={css({
                marginBottom: '8px'
              })}
            />
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <GuideList />
        </Cell>
      </Grid>
    </>
  )
}

const LayoutGuideListPage = () => {
  return (
    <Layout searchBarOpen={false}>
      <GuideListPage />
    </Layout>
  )
}

export default LayoutGuideListPage
